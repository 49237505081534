import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { redirect } from 'redux-first-router'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { DrawerContext } from 'subapps/settings/components/drawers/contexts'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import {
  selectPendingRuleForId,
  selectPendingRuleActionsByIds,
  selectPendingRuleConditionsByIds,
  selectPendingRuleTriggersByIds,
  selectPendingRuleActionMessageTemplatesById,
  selectIsLoadingRule,
  selectHasLoadedRule,
  selectHasErrorRule,
} from 'ducks/rules/selectors'
import { selectIsPaidAccount } from 'ducks/billing/selectors'
import {
  doUpdateRule,
  doCreateRule,
  doFetchRulesById,
  doClearAllRuleDrafts,
  doSaveRuleDraft,
} from 'ducks/rules/actions'
import { isValidEmail, isValidDomain } from 'util/strings'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import { SETTINGS_RULES_PAGE } from 'subapps/settings/types'
import { FEATURE_INBOX_MAX_RULES } from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { selectRequestByType } from 'ducks/requests/selectors'
import { FETCH_RULE_FEATURE_TEMPLATES } from 'ducks/featureTemplates/types'
import { selectCurrentFeatureTemplateTemplateDataForId } from 'ducks/featureTemplates/selectors'
import debug from 'util/debug'
import { styles } from './styles'
import {
  ruleTriggersBySettingInputNeeded,
  ruleTriggersRequireConditions,
} from './data'
import RuleConditions from './RuleConditions'
import RuleActions from './RuleActions'
import StateSwitch from './StateSwitch'
import RuleTriggers from './RuleTriggers'
import { styles as automationItemStyles } from '../../AutomationItem/styles'
import { styles as menuWithSearchStyles } from '../../MenuWithSearch/styles'
import ScheduleSection, { scheduleTypes } from './ScheduleSection'

// eslint-disable-next-line func-names
yup.addMethod(yup.array, 'ruleConditions', function() {
  // eslint-disable-next-line func-names
  return this.test('ruleConditions', 'errorMessage', function(value) {
    const { path, createError, parent } = this
    let isValid = false

    if (!value.length) {
      const someTriggersRequireConditions = parent.triggers
        .map(trigger => trigger.type)
        .some(triggerType =>
          ruleTriggersRequireConditions.includes(triggerType)
        )

      if (!someTriggersRequireConditions) {
        return true
      }
    }

    if (value) {
      const validFields = value.filter(
        field => !!field.operator && !!field.param
      )
      if (validFields.length > 0) isValid = true
    }

    return isValid || createError({ path, message: 'No conditions set' })
  })
})

// eslint-disable-next-line func-names
yup.addMethod(yup.object, 'ruleCondition', function() {
  // eslint-disable-next-line func-names
  return this.test('rule-condition', 'errorMessage', function(field) {
    if (!field) return true
    const { createError, options } = this
    const { index } = options
    const { operator, param, value } = field

    // we ignore empty conditions - these are handled by ruleConditions validation
    if (!operator || !param) return true

    if (['TO_EMAIL', 'FROM_EMAIL'].includes(param)) {
      if (['DOMAIN_IS', 'DOMAIN_IS_NOT'].includes(operator)) {
        if (!isValidDomain(value)) {
          return createError({
            path: `conditions.${index}`,
            message: 'Please provide correct domain',
          })
        }
      } else if (['IS', 'IS_NOT'].includes(operator) && !isValidEmail(value)) {
        return createError({
          path: `conditions.${index}`,
          message: 'Invalid email',
        })
      }
    }

    if (
      ['DOMAIN_IS', 'DOMAIN_IS_NOT'].includes(operator) &&
      !isValidDomain(value)
    ) {
      return createError({
        path: `conditions.${index}`,
        message: 'Please provide correct domain',
      })
    }

    if (value === null || value === undefined || value === '') {
      return createError({
        path: `conditions.${index}`,
        message: 'Value required',
      })
    }

    return true // isValid || createError({ path, message: 'Not valid' })
  })
})

const extractValidActions = fields => {
  return fields.filter(field => !!field.type)
}

// eslint-disable-next-line func-names
yup.addMethod(yup.array, 'ruleActions', function() {
  // eslint-disable-next-line func-names
  return this.test(`ruleActions`, 'errorMessage', function(value) {
    const { path, createError } = this
    let isValid = false
    if (value) {
      const validFields = extractValidActions(value)
      if (validFields.length > 0) isValid = true
    }

    return isValid || createError({ path, message: 'Not valid' })
  })
})

// eslint-disable-next-line func-names
yup.addMethod(yup.object, 'ruleAction', function() {
  // eslint-disable-next-line func-names
  return this.test('rule-action', 'errorMessage', function(field) {
    if (!field) return true
    const { createError, options } = this
    const { index } = options
    const { type, value } = field

    if (type === 'FORWARD_CONVERSATION' && !isValidEmail(value)) {
      return createError({
        path: `actions.${index}`,
        message: 'Invalid email',
      })
    }

    if (
      [
        'SEND_EMAIL_TO_AGENTS',
        'SEND_EMAIL_REPLY',
        'SEND_IN_APP_NOTIFICATION_TO_AGENTS',
      ].includes(type)
    ) {
      if (!(field?.toAgent || '').trim()) {
        return createError({
          path: `actions.${index}`,
          message: `Select ${app.t('agents')}`,
        })
      }
    }

    if (['SEND_EMAIL_TO_AGENTS', 'SEND_EMAIL_REPLY'].includes(type)) {
      if (!(field?.messageTemplate?.body || '').trim()) {
        return createError({
          path: `actions.${index}`,
          message: 'Invalid message',
        })
      }
    }

    if (
      type === 'SEND_IN_APP_NOTIFICATION_TO_AGENTS' &&
      !(value || '').trim()
    ) {
      return createError({
        path: `actions.${index}`,
        message: 'Invalid message',
      })
    }

    if (type === 'SEND_EMAIL_TO_REQUESTER') {
      if (!(field?.messageTemplate?.body || '').trim()) {
        return createError({
          path: `actions.${index}`,
          message: 'Invalid message',
        })
      }
    }

    if (type === 'LABELS' && !value) {
      return createError({
        path: `actions.${index}`,
        message: 'Select tags',
      })
    }

    if (type === 'ASSIGNEE_ID' && !value) {
      return createError({
        path: `actions.${index}`,
        message: `Select ${app.t('agent')}`,
      })
    }

    return true
  })
})

// eslint-disable-next-line func-names
yup.addMethod(yup.array, 'ruleTriggers', function() {
  // eslint-disable-next-line func-names
  return this.test(`ruleTriggers`, 'errorMessage', function(value) {
    const { path, createError } = this
    let isValid = false

    if (value.length) {
      const validFields = value.filter(field => !!field.type).filter(field => {
        const hasSettingInput =
          field.settings?.trigger_input &&
          field.settings.trigger_input.length > 0

        if (ruleTriggersBySettingInputNeeded[field.type]) {
          return !!hasSettingInput
        }

        return true
      })

      if (validFields.length === value.length) isValid = true
    }

    return isValid || createError({ path, message: 'Not valid' })
  })
})

const scheduleSettingsSchema = {
  AFTER: yup.object({
    seconds: yup
      .number()
      .positive()
      .integer()
      .required(),
  }),
  ON_DAY: yup.object({
    day: yup
      .number()
      .min(1)
      .max(31)
      .required(),
    time: yup
      .string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/)
      .required(), // HH:mm format
  }),
  ON_NEXT: yup.object({
    dayOfWeek: yup
      .number()
      .min(0)
      .max(6)
      .required(),
    time: yup
      .string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/)
      .required(), // HH:mm format
  }),
  BEFORE_MONTH: yup.object({
    days: yup
      .number()
      .positive()
      .integer()
      .required(),
    time: yup
      .string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/)
      .required(), // HH:mm format
  }),
  AFTER_BUSINESS_HOURS: yup.object({
    seconds: yup
      .number()
      .positive()
      .integer()
      .required(),
  }),
  AFTER_BUSINESS_HOURS_START: yup.object({
    seconds: yup
      .number()
      .positive()
      .integer()
      .required(),
  }),
  BEFORE_BUSINESS_HOURS_END: yup.object({
    seconds: yup
      .number()
      .positive()
      .integer()
      .required(),
  }),
}

// eslint-disable-next-line func-names
yup.addMethod(yup.object, 'scheduleSettings', function() {
  // eslint-disable-next-line func-names
  return this.test('schedule-settings', 'Invalid schedule settings', function(
    value
  ) {
    const { createError } = this
    const { scheduleType } = this.parent
    const validationSchema = scheduleSettingsSchema[scheduleType]

    if (!validationSchema) return true

    try {
      validationSchema.validateSync(value)
      return true
    } catch (err) {
      return createError({
        path: this.path,
        message: err.message,
      })
    }
  })
})

// keys should preferably match the object being stored in the entity store
// that way for updates, you can just spread the entity in reset() and not have to set each property individually
const FORM_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('Rule name is required'),
  active: yup.boolean(),
  scheduleType: yup
    .string()
    .nullable()
    .oneOf([...Object.values(scheduleTypes).map(type => type.id), null]),
  scheduleSettings: yup.object().scheduleSettings(),
  conditions: yup
    .array()
    .ruleConditions()
    .of(yup.object().ruleCondition()),
  actions: yup
    .array()
    .ruleActions()
    .of(yup.object().ruleAction()),
  triggers: yup.array().ruleTriggers(),
})

const EMPTY_FORM_STATE = {
  // 1. defaultValue has to match yup form schema
  // 2. don't set key value for array fields, useFieldArray will set it
  // so the array fields won't be dirty if only their keys are different
  id: null,
  name: '',
  active: true,
  matchType: 'ALL',
  scheduleType: 'IMMEDIATELY',
  scheduleSettings: {
    day: null,
    dayOfWeek: null,
    days: null,
    seconds: null,
    time: null,
  },
  triggers: [],
  conditions: [],
  actions: [],
  stopUpcoming: false,
}

export default function RuleCreateEditDrawer({
  open,
  onClose,
  onExit,
  drawerResourceId,
  drawerId,
  drawer,
  drawerTemplateId: templateId,
  ...rest
}) {
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(null)
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_RULES)
  const showPlanLimitation = !canUseFeature

  const isUpdate = drawerResourceId !== 'new'
  const isFromTemplate = !isUpdate && isDefined(templateId)
  const [areFormFieldsLoaded, setAreFormFieldsLoaded] = useState(!isUpdate)

  // selectors
  const isPaidAccount = useSelector(selectIsPaidAccount)

  const entity = useSelector(state =>
    selectPendingRuleForId(state, drawerResourceId)
  )

  const ruleActionsEntities = useSelector(state =>
    selectPendingRuleActionsByIds(state, entity?.actions)
  )

  const ruleConditionsEntities = useSelector(state =>
    selectPendingRuleConditionsByIds(state, entity?.conditions)
  )

  const ruleTriggersEntities = useSelector(state =>
    selectPendingRuleTriggersByIds(state, entity?.triggers)
  )

  const ruleActionMessageTemplatesEntities = useSelector(
    selectPendingRuleActionMessageTemplatesById
  )

  const featureTemplateData = useSelector(state =>
    selectCurrentFeatureTemplateTemplateDataForId(state, templateId)
  )

  useEffect(
    () => {
      // need to check drawer open state before fetch dispatches
      // otherwise the dispatch methods in useEffect below will run immediately
      // after this Drawer component is instantiated via `useDrawer` (before openDrawer is even called)
      // Which is unnecessary double dispatch triggering
      if (!open) return

      // only fetch if there is no entity draft in pending store
      if (isUpdate && !entity) {
        dispatch(doFetchRulesById(drawerResourceId, { targetStore: 'pending' }))
      }
    },
    [dispatch, isUpdate, drawerResourceId, open, entity]
  )

  const isLoadingRule = useSelector(selectIsLoadingRule)
  const hasLoadedRule = useSelector(selectHasLoadedRule)
  const hasErrorRule = useSelector(selectHasErrorRule)

  const {
    loading: isLoadingTemplateData,
    loaded: hasLoadedTemplateData,
  } = useSelector(state =>
    selectRequestByType(state, FETCH_RULE_FEATURE_TEMPLATES)
  )

  const isLoading =
    isLoadingRule || isLoadingTemplateData || !areFormFieldsLoaded

  const updateEntityNotFound = isUpdate ? hasLoadedRule && !entity : false
  const templateDataNotFound = isFromTemplate
    ? hasLoadedTemplateData && !featureTemplateData
    : false
  const notFound = updateEntityNotFound || templateDataNotFound

  const resolver = yupResolver(FORM_SCHEMA, { abortEarly: false })
  // form
  const useFormMethods = useForm({
    mode: 'all',
    resolver,
    defaultValues: EMPTY_FORM_STATE,
  })

  const {
    register,
    trigger,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    watch,
    getValues,
  } = useFormMethods

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  const handleBeforeCloseOrExit = useCallback(
    () => {
      // clearing all drafts,
      // otherwise we would have to individually clear each action, trigger, condition, template by id
      // We only create one rule model at a time anyway
      dispatch(doClearAllRuleDrafts())
      reset(EMPTY_FORM_STATE)
    },
    [dispatch, reset]
  )

  const handleClose = useConfirmHoldsCallback(
    holdKey,
    () => {
      handleBeforeCloseOrExit()
      onClose()
    },
    [handleBeforeCloseOrExit, onClose]
  )

  const handleExit = useConfirmHoldsCallback(
    null,
    () => {
      handleBeforeCloseOrExit()
      onExit()
    },
    [reset, handleBeforeCloseOrExit]
  )

  const onSubmit = useCallback(
    async data => {
      setIsSaving(true)

      try {
        if (isUpdate) {
          await dispatch(doUpdateRule(data))
          releaseHold()
        } else {
          await dispatch(
            doCreateRule(data, {
              onBeforeSuccessAction: () => {
                releaseHold()
              },
            })
          )
        }
        setIsSaving(false)
        handleClose()
      } catch (e) {
        debug('Failed to save rule', { error: e, data })
        setIsSaving(false)
      }
    },
    [dispatch, handleClose, releaseHold, isUpdate]
  )

  const onSaveDraft = useCallback(
    () => {
      dispatch(doSaveRuleDraft(drawerResourceId, getValues()))
      releaseHold()
    },
    [releaseHold, drawerResourceId, dispatch, getValues]
  )

  const drawerContextValue = useMemo(() => ({ onSaveDraft }), [onSaveDraft])

  // We need to add messageTemplate to each action
  // so react-hook-form won't consider the form is dirty without changes
  // when those fields (body, attachments, subject) are registered
  const ruleActionsForForm = useMemo(
    () => {
      return ruleActionsEntities.map(action => {
        const ruleReplyTemplateId = action.replyTemplate
        if (ruleReplyTemplateId) {
          const ruleReplyTemplate =
            ruleActionMessageTemplatesEntities[ruleReplyTemplateId]
          return {
            ...action,
            messageTemplate: {
              body: ruleReplyTemplate?.body,
              attachments: ruleReplyTemplate?.attachments || [],
              subject: ruleReplyTemplate?.subject,
            },
          }
        }
        return action
      })
    },
    // We intentionally exclude dependency props (ruleActionMessageTemplatesEntities) here to ensure that this
    // reset only triggers for the initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ruleActionsEntities]
  )

  // Effects
  useEffect(
    () => {
      // entity can be from create or update draft
      if (entity) {
        reset({
          // just a spread here cos the needed keys should match the form schema
          ...entity,
          conditions: ruleConditionsEntities.map(c => ({
            ...c,
            param:
              c.param === 'CUSTOM_FIELD'
                ? `CUSTOM_FIELD_${c.source.key}`
                : c.param,
          })),
          actions: ruleActionsForForm,
          triggers: ruleTriggersEntities,
          // If scheduleType and scheduleSettings are null, should match the default values
          scheduleType: entity.scheduleType || EMPTY_FORM_STATE.scheduleType,
          scheduleSettings:
            entity.scheduleSettings || EMPTY_FORM_STATE.scheduleSettings,
        })
      } else if (isFromTemplate && featureTemplateData) {
        reset(
          {
            // just a spread here cos the needed keys should match the form schema
            ...featureTemplateData,
            // If scheduleType and scheduleSettings are null, should match the default values
            scheduleType:
              featureTemplateData.scheduleType || EMPTY_FORM_STATE.scheduleType,
            scheduleSettings:
              featureTemplateData.scheduleSettings ||
              EMPTY_FORM_STATE.scheduleSettings,
          },
          {
            keepDefaultValues: true,
          }
        )
      }
    },
    // We intentionally exclude dependency props here to ensure that this
    // reset only triggers for the initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      entity,
      isUpdate,
      ruleConditionsEntities,
      ruleActionsForForm,
      ruleTriggersEntities,
      featureTemplateData,
      isFromTemplate,
    ]
  )

  // Effects
  useEffect(
    () => {
      if ((entity && isUpdate) || (featureTemplateData || isFromTemplate)) {
        setAreFormFieldsLoaded(true)
      }
    },
    [entity, isUpdate, isFromTemplate, featureTemplateData]
  )

  // const formId = `${drawer}-${drawerId}-${drawerResourceId}`

  const footer = useMemo(
    () => {
      const isDirtyOrDraft = isDirty || entity?.isDraft
      return (
        <ModalBtns
          saveBtnDisabled={!isValid || isSaving || !isDirtyOrDraft}
          saveBtnText={
            <>
              {isSaving && (
                <span>
                  Saving<AnimatedEllipsis />
                </span>
              )}
              {!isSaving && isUpdate && 'Save'}
              {!isSaving && !isUpdate && 'Create'}
            </>
          }
          // type="submit" will automatically trigger the form submit event when clicked
          saveBtnHtmlType="submit"
          //  saveBtnForm={formId}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleClose}
        />
      )
    },
    [isValid, isSaving, isDirty, isUpdate, handleClose, entity]
  )

  const DrawerForm = useCallback(
    subprops => <form onSubmit={handleSubmit(onSubmit)} {...subprops} />,
    [handleSubmit, onSubmit]
  )

  useEffect(
    () => {
      if (showPlanLimitation && !isUpdate) {
        dispatch(
          redirect({
            type: SETTINGS_RULES_PAGE,
          })
        )
      }
    },
    [showPlanLimitation, dispatch, isUpdate]
  )

  if (!drawer) return null

  const nameErrorsMessage = errors?.name?.message
  const showStopUpcoming = watch().scheduleType === 'IMMEDIATELY'

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={handleExit}
      size="wide"
      title={isUpdate ? 'Edit rule' : 'Create a rule'}
      isLoading={isLoading}
      isError={hasErrorRule}
      isNoResultFound={notFound}
      container={DrawerForm}
      footer={footer}
    >
      <DrawerContext.Provider value={drawerContextValue}>
        <FormProvider {...useFormMethods}>
          <div className="grui pl-5 pb-10">
            <div className="grui pt-10">
              <div css={styles.topFields} className="grui mb-12">
                <Field
                  {...register('name')}
                  label="Rule name"
                  placeholder="Enter rule name..."
                  name="name"
                  validateStatus={nameErrorsMessage ? 'error' : undefined}
                  help={nameErrorsMessage}
                  className="grui flex-grow"
                  css={automationItemStyles.itemWidth}
                />
                <div
                  css={[fieldStyles.labelBox, styles.stateSwitch]}
                  className="grui -mr-7"
                >
                  <StateSwitch control={control} />
                </div>
              </div>
            </div>
            <div className="grui mb-12">
              <div css={styles.label} className="grui mt-11 mb-7">
                When this happens...
              </div>
              <RuleTriggers
                control={control}
                register={register}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div className="grui mb-12">
              <div css={styles.label} className="grui mt-11 mb-7">
                Then...
              </div>
              <ScheduleSection
                control={control}
                register={register}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div className="grui mb-12">
              <div css={styles.label} className="grui mt-11 mb-7">
                Check these conditions...
              </div>
              <RuleConditions
                control={control}
                errors={errors.conditions}
                register={register}
                trigger={trigger}
                setValue={setValue}
                conditionMatchTypeName="matchType"
              />
            </div>
            <div>
              <div css={styles.label} className="grui mt-11 mb-7">
                And run these actions...
              </div>
              <RuleActions
                control={control}
                errors={errors.actions}
                setValue={setValue}
                trigger={trigger}
                isPaidAccount={isPaidAccount}
              />
            </div>
            {showStopUpcoming && (
              <Checkbox
                className="grui mt-12"
                id="stopUpcoming"
                {...register('stopUpcoming')}
              >
                Don&apos;t process any upcoming Rules if this rule runs
                <Tooltip
                  title="If this Rule's conditions are met and it runs, we will stop any further rules from running after this."
                  strategy="fixed"
                  portal="#overlays"
                  css={styles.tooltip}
                >
                  <div
                    css={menuWithSearchStyles.tooltipIcon}
                    className="grui inline-flex"
                  >
                    i
                  </div>
                </Tooltip>
              </Checkbox>
            )}
          </div>
        </FormProvider>
      </DrawerContext.Provider>
    </AdminAccessDrawer>
  )
}
