import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ColorPicker from '@groovehq/internal-design-system/lib/components/ColorPicker/ColorPicker'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useChannel } from 'ducks/channels/hooks'
import { selectCurrentChannelCount } from 'ducks/channels/selectors'
import DynamicPortal from 'components/DynamicPortal'
import { promisify } from 'util/promises'
import { useRhfDirtyHold } from 'util/dirtyHolds'
import {
  IMAP_SERVER,
  SMTP_SERVER,
  EMAIL_SERVER_DOMAIN_PORT,
  EMAIL_SERVER_AUTH_SSL,
} from 'ducks/mailboxes/constants'
import { isIMAPChannelType } from 'ducks/channels/channelTypes'

import getConfig, { DEFAULT_COLORS } from './config'
import ServerDetails from './ServerDetails'
import { styles } from '../styles'

const ChannelDetailsForm = React.forwardRef(
  (
    {
      channelId,
      channelType,
      formId,
      actionsPortalRef,
      actionsComponent: ActionsComponent,
      actionsComponentAdditionalProps,
      inDrawer = false,
      onSubmit: onSubmitParam = () => {
        return Promise.resolve()
      },
      resetAfterSubmit = false,
      showColorPicker = false,
      showForwardEmail = true,
      shouldHideGeneralFields = false,
      showSenderName = false,
    },
    forwardedRef
  ) => {
    const { channel } = useChannel(channelId, { useCachedIfAvailable: true })
    const isIMAPChannel = isIMAPChannelType(channelType)
    const shouldShowIMAPFields = isIMAPChannel && inDrawer

    const imapServer = useMemo(() => channel?.[IMAP_SERVER] || {}, [channel])
    const smtpServer = useMemo(
      () => {
        const result = channel?.[SMTP_SERVER] || {}
        if (!result[EMAIL_SERVER_DOMAIN_PORT]) {
          result[EMAIL_SERVER_DOMAIN_PORT] = 465
        }
        return result
      },
      [channel]
    )

    const { schema: FORM_SCHEMA, showEmail, formEmptyState } = useMemo(
      () =>
        getConfig({
          imapServer: shouldShowIMAPFields ? imapServer : null,
          smtpServer: shouldShowIMAPFields ? smtpServer : null,
        })[channelType],
      [imapServer, smtpServer, channelType, shouldShowIMAPFields]
    )
    const channelCount = useSelector(selectCurrentChannelCount)
    const shouldShowEmail =
      showEmail && !shouldHideGeneralFields && (isIMAPChannel ? inDrawer : true)

    let defaultValues = {
      ...formEmptyState,
      id: channelId,
      color: DEFAULT_COLORS[channelCount % DEFAULT_COLORS.length],
    }

    if (shouldShowIMAPFields) {
      defaultValues = {
        ...defaultValues,
        [IMAP_SERVER]: {
          ...imapServer,
          [EMAIL_SERVER_AUTH_SSL]: imapServer[EMAIL_SERVER_AUTH_SSL] !== false,
        },
        [SMTP_SERVER]: {
          ...smtpServer,
        },
      }
    }

    if (channel) {
      defaultValues = {
        ...defaultValues,
        name: channel.name,
        sender_name: channel.sender_name,
        email: channel.email,
        color: channel.color,
      }
    }

    const {
      register,
      handleSubmit,
      reset,
      control,
      setFocus,
      setValue,
      watch,
      formState: { errors },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(FORM_SCHEMA),
      defaultValues,
      delayError: 1000,
    })

    const { releaseHold } = useRhfDirtyHold(formId, control)
    const { onChange: colorOnChange, name: colorName } = register('color')
    const registeredEmailField = register('email')
    const registeredNameField = register('name')
    const registeredSenderNameField = register('sender_name')

    const colorPickerRef = useRef(null)
    const [focusedFields, setFocusedFields] = useState({})

    const handleFocus = useCallback(e => {
      e.persist()
      setFocusedFields(previousState => {
        return {
          ...previousState,
          [e.target.name]: true,
        }
      })
    }, [])

    const handleBlur = useCallback(
      e => {
        e.persist()
        setFocusedFields(previousState => {
          return {
            ...previousState,
            [e.target.name]: false,
          }
        })
        switch (e.target.name) {
          case registeredEmailField.name:
            return registeredEmailField.onBlur(e)
          case registeredNameField.name:
            return registeredNameField.onBlur(e)
          case registeredSenderNameField.name:
            return registeredSenderNameField.onBlur(e)
          default:
            return null
        }
      },
      [registeredEmailField, registeredNameField, registeredSenderNameField]
    )

    const handleColorChange = useCallback(
      colorValue => {
        colorOnChange({
          target: {
            name: colorName,
            value: colorValue,
          },
        })
      },
      [colorOnChange, colorName]
    )

    const onSubmit = useCallback(
      data => {
        if (resetAfterSubmit) {
          return promisify(onSubmitParam, data).then(() => {
            reset(data)
          })
        }
        releaseHold()
        return onSubmitParam(data)
      },
      [resetAfterSubmit, onSubmitParam, reset, releaseHold]
    )

    const onReset = useCallback(
      e => {
        releaseHold()
        reset()
        colorPickerRef.current.setColor(defaultValues.color)
        e.preventDefault()
      },
      [reset, colorPickerRef, defaultValues.color, releaseHold]
    )

    const isForwarding = channelType === 'forwarding'

    const nameLabelValue =
      channelType === 'facebook' || channelType === 'instagram'
        ? 'The channel display name'
        : `${app.t('Mailbox')} name`
    const senderNameLabelValue =
      channelType === 'facebook' || channelType === 'instagram'
        ? 'The channel sender name'
        : `${app.t('Mailbox')} sender name`

    return (
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        ref={forwardedRef}
      >
        <div>
          {!shouldHideGeneralFields && (
            <Field
              label={nameLabelValue}
              placeholder="i.e. support"
              className="grui mt-12 channel-details-field"
              css={inDrawer && styles.fullWidth}
              validateStatus={
                !focusedFields.name && errors?.name ? 'error' : undefined
              }
              help={!focusedFields.name ? errors?.name?.message : undefined}
              {...registeredNameField}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
          {!shouldHideGeneralFields &&
            showSenderName && (
              <Field
                label={senderNameLabelValue}
                placeholder="i.e. support"
                className="grui mt-12 channel-details-field"
                css={inDrawer && styles.fullWidth}
                validateStatus={
                  !focusedFields.sender_name && errors?.sender_name
                    ? 'error'
                    : undefined
                }
                help={
                  !focusedFields.sender_name
                    ? errors?.sender_name?.message
                    : undefined
                }
                {...registeredSenderNameField}
                onFocus={handleFocus}
                onBlur={handleBlur}
                tooltipTitle={
                  <>
                    Customers see this name in the "From" field when they view
                    your email.
                    <br />
                    If left blank, {app.t('mailbox')} name will be used.
                  </>
                }
              />
            )}
          {shouldShowEmail && (
            <>
              <Field
                label="Email address"
                placeholder="i.e. support@acme.com"
                className="grui mt-12 channel-details-field"
                css={inDrawer && styles.fullWidth}
                validateStatus={
                  !focusedFields.email && errors?.email ? 'error' : undefined
                }
                help={!focusedFields.email ? errors?.email?.message : undefined}
                disabled={!isForwarding && !isIMAPChannel}
                {...registeredEmailField}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {showForwardEmail &&
                channel &&
                !isIMAPChannel && (
                  <p
                    css={[
                      paragraph.styles.preflight,
                      text.styles.textMediumDark,
                    ]}
                    className="grui mt-3"
                  >
                    {isForwarding
                      ? `Forwarded to ${channel.forwardEmailAddress}`
                      : 'Connected via OAuth'}
                  </p>
                )}
            </>
          )}
          {showColorPicker && (
            <>
              <div
                css={[
                  fieldStyles.labelBox,
                  styles.colorLabel,
                  inDrawer && styles.fullWidth,
                ]}
                className="grui mt-12"
              >
                Color
              </div>
              <ColorPicker
                defaultColor={defaultValues.color}
                pickerBtnStyles={styles.colorPickerBtn}
                css={styles.popoverContainer}
                onColorChange={handleColorChange}
                ref={colorPickerRef}
                colorType="rgb"
                dataTestId="button-channel-details-color-picker"
              />
            </>
          )}
          {shouldShowIMAPFields && (
            <ServerDetails
              className="grui mt-12"
              register={register}
              control={control}
              errors={errors[IMAP_SERVER]}
              setFocus={setFocus}
              imapServerId={imapServer.id}
              setValue={setValue}
              watch={watch}
            />
          )}
          <DynamicPortal portalRef={actionsPortalRef}>
            <ActionsComponent
              {...actionsComponentAdditionalProps}
              control={control}
              formId={formId}
            />
          </DynamicPortal>
        </div>
      </form>
    )
  }
)

ChannelDetailsForm.displayName = 'ChannelDetailsForm'

export default ChannelDetailsForm
