import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { styles } from '../MultiSelectMenu/styles'

export default function Option({
  id,
  name,
  color,
  conversationCount,
  onChange,
  checked,
  indeterminate = false,
}) {
  return (
    <Checkbox
      css={styles.checkbox}
      id={id}
      onChange={onChange}
      checked={checked}
      indeterminate={indeterminate}
      truncate
    >
      <div className="grui truncate">
        {color && <Dot color={color} size="small" className="grui mr-3 mb-2" />}
        {`${name}${
          isDefined(conversationCount) ? ` (${conversationCount})` : ''
        }`}
      </div>
    </Checkbox>
  )
}
