import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { emptyArr, areArraysEqual } from 'util/arrays'
import usePrevious from 'util/hooks/usePrevious'
import {
  DRAWER_TYPE_TAGS_UPDATE,
  DRAWER_TYPE_TAGS_OVERVIEW,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { doFetchTagsV2ByIdsOrNames } from 'ducks/tags/actions'
import { selectCurrentTags, selectCurrentTagsById } from 'ducks/tags/selectors'
import { isGid } from 'util/globalId'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import TagDropdownCreateButton from '../TagDropdown/createButton'
import Option from './Option'
import useSearchTags from './useSearchTags'
import {
  Footer,
  styles,
  useMultiSelect,
  DROPDOWN_MODE_STANDARD,
} from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import Content from './Content'
import { DROPDOWN_MODE_INDETERMINATE } from '../MultiSelectMenu/useMultiSelect'
import { TAG_DROPDOWN_VARIANTS } from './constants'

const TagsDropdown = ({
  fieldType = 'ids',
  value: inputValues,
  setValue,
  selectedIds,
  onSelectedIdsChange,
  onVisibleChange,
  className,
  visible = false,
  offset = null,
  mode = DROPDOWN_MODE_STANDARD,
  children,
  variant = TAG_DROPDOWN_VARIANTS.DEFAULT,
}) => {
  const isIntermediateMode = mode === DROPDOWN_MODE_INDETERMINATE
  if (fieldType !== 'ids' && isIntermediateMode) {
    throw new Error(
      `InvalidArgumentExcpetion TagsDropdown does not support mode ${mode} with fieldType ${fieldType}`
    )
  }
  const searchRef = useRef()
  const dispatch = useDispatch()
  const values = inputValues || emptyArr
  const [menuVisible, setMenuVisible] = useState(visible)
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )
  const currentTagsById = useSelector(selectCurrentTagsById)
  const currentTags = useSelector(selectCurrentTags)

  const unsavedTags = useMemo(
    () => {
      if (fieldType !== 'ids') return []

      const ids = isIntermediateMode ? values.map(v => v.id) : values

      return ids.filter(v => !!v && !isGid(v))
    },
    [fieldType, values, isIntermediateMode]
  )

  const {
    search,
    handleChangeSearch,
    filteredTags,
    loading,
    isEmpty,
    reload: reloadSearch,
  } = useSearchTags({
    searchDelayMs: 200,
    field: fieldType,
    prependNames: unsavedTags,
    disabled: !menuVisible,
    includeSelected: variant === TAG_DROPDOWN_VARIANTS.INBOX,
    selectedIds,
  })
  const [sortedTags, setSortedTags] = useState(filteredTags)
  const {
    handleToggleItem,
    initialCheckedList: initialSelectedIds,
  } = useMultiSelect(sortedTags, onSelectedIdsChange, selectedIds, mode)

  const hasChanged = useMemo(
    () => {
      return (
        variant !== TAG_DROPDOWN_VARIANTS.DEFAULT &&
        !areArraysEqual(selectedIds, initialSelectedIds)
      )
    },
    [selectedIds, initialSelectedIds, variant]
  )

  const handleOnCreated = useCallback(
    async tagId => {
      if (searchRef.current) {
        searchRef.current.focus()
      }
      await reloadSearch()
      handleToggleItem(tagId)
    },
    [reloadSearch, handleToggleItem, searchRef]
  )

  const {
    openDrawer: openTagCreateDrawer,
    drawerId: tagCreateDrawerId,
  } = useDrawer(DRAWER_TYPE_TAGS_UPDATE)
  const { openDrawer: openManageDrawer } = useDrawer(DRAWER_TYPE_TAGS_OVERVIEW)

  const handleOnCreateTag = useCallback(
    () => {
      openTagCreateDrawer('new', {
        query: {
          ...(variant === TAG_DROPDOWN_VARIANTS.INBOX
            ? buildDrawerQueryParam(
                tagCreateDrawerId,
                // this will open the tags assignment dropdown on save or close of create drawer
                'drawerOpenTagsAssignModalOnExit',
                true
              )
            : {}),
        },
        additionalProps: {
          // if passed, prefill the name form field with value
          initialTagName: search,
          onCreated: handleOnCreated,
        },
      })
    },
    [openTagCreateDrawer, tagCreateDrawerId, search, handleOnCreated, variant]
  )

  const handleOnEnter = useCallback(
    () => {
      if (!loading && !!search && isEmpty) {
        handleOnCreateTag()
      }
    },
    [handleOnCreateTag, isEmpty, search, loading]
  )

  const handleMenuVisibleChange = useCallback(
    inputVisible => {
      setMenuVisible(inputVisible)
      if (onVisibleChange) onVisibleChange(inputVisible)
    },
    [onVisibleChange]
  )

  const handleSelect = useCallback(
    () => {
      setValue(selectedIds, initialSelectedIds)
      setMenuVisible(false)
    },
    [setValue, selectedIds, initialSelectedIds]
  )
  const handleCancel = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch
          search={search}
          onScroll={handleScroll}
          focusElementRef={searchRef}
          onApply={handleSelect}
          onEnter={handleOnEnter}
        >
          {variant === TAG_DROPDOWN_VARIANTS.DEFAULT && (
            <>
              <TagDropdownCreateButton
                onCreateTag={handleOnCreateTag}
                className="grui px-10 pb-5"
              />
              {divider}
            </>
          )}
          <div className="grui pt-3 pb-5">
            <Content
              loading={loading}
              isEmpty={isEmpty}
              onCreate={handleOnCreateTag}
              variant={variant}
            >
              {sortedTags.map(({ id, name, conversationCount, color }) => {
                const item = selectedIds.find(itemOrId => {
                  if (isIntermediateMode) {
                    return itemOrId.id === id
                  }
                  return itemOrId === id
                })
                const checked = isIntermediateMode
                  ? item?.state === true
                  : !!item
                const indeterminate = isIntermediateMode
                  ? item?.state === null
                  : false

                return (
                  <MenuWithSearch.Item
                    itemKey={id}
                    key={id}
                    onSelect={handleToggleItem}
                  >
                    <Option
                      css={styles.checkbox}
                      id={id}
                      checked={checked}
                      name={name}
                      conversationCount={conversationCount || 0}
                      color={color}
                      indeterminate={indeterminate}
                    />
                  </MenuWithSearch.Item>
                )
              })}
            </Content>
          </div>
        </MenuWithSearch>
      )
    },
    [
      sortedTags,
      selectedIds,
      handleOnCreateTag,
      handleScroll,
      search,
      handleToggleItem,
      loading,
      isEmpty,
      handleSelect,
      isIntermediateMode,
      variant,
      handleOnEnter,
    ]
  )
  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search all tags"
        onChange={handleChangeSearch}
        value={search}
        ref={searchRef}
        shouldFocus={menuVisible}
      />
    ),
    [handleChangeSearch, search, menuVisible]
  )
  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        onCreate={handleOnCreateTag}
        onManage={openManageDrawer}
        saveBtnTitle="Select tags"
        variant={variant}
        hasChanged={hasChanged}
      />
    ),
    [
      handleSelect,
      handleCancel,
      handleOnCreateTag,
      openManageDrawer,
      variant,
      hasChanged,
    ]
  )

  const selectedNames = useMemo(
    () => {
      if (fieldType === 'ids') {
        const idsOrValues = isIntermediateMode ? values.map(v => v.id) : values
        return idsOrValues
          .map(
            idOrName =>
              (isGid(idOrName) && currentTagsById[idOrName]?.name) ||
              (!isGid(idOrName) &&
                (currentTags.find(ct => ct?.name === idOrName) || idOrName))
          )
          .filter(name => !!name)
          .join(', ')
      }

      return values.filter(name => !!name).join(', ')
    },
    [fieldType, values, currentTagsById, currentTags, isIntermediateMode]
  )

  useEffect(
    () => {
      if (!menuVisible) {
        onSelectedIdsChange(values)
        handleChangeSearch({ target: { value: '' } })
      }
    },
    // Including onSelectedIdsChange causes circular renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuVisible, values]
  )

  const previousValues = usePrevious(values)
  useEffect(
    () => {
      let filter = []

      if (fieldType === 'ids') {
        const ids = isIntermediateMode ? values.map(v => v.id) : values
        filter = ids.filter(tagId => !currentTagsById[tagId])
      } else if (fieldType === 'names') {
        filter = values.filter(
          tagName => !currentTags.find(ct => ct?.name === tagName)
        )
      }

      if (
        filter &&
        filter.length &&
        !areArraysEqual(filter, previousValues || [])
      ) {
        dispatch(doFetchTagsV2ByIdsOrNames(filter))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldType, values, previousValues, isIntermediateMode]
  )

  const normalizedValues = useMemo(
    () => {
      if (fieldType !== 'ids') return values

      // if any of the tags in values is a not a gid, try find it from current tags
      const ids = isIntermediateMode ? values.map(v => v.id) : values
      return ids.map(v => {
        if (isGid(v)) return v

        return currentTags.find(ct => ct?.name === v)?.id || v
      })
    },
    [fieldType, currentTags, values, isIntermediateMode]
  )

  useEffect(
    () => {
      if (fieldType !== 'ids') return

      // if a string non-id was passed as a value and a match was found in currenttags,
      // update values to use id instead
      if (!isIntermediateMode && !areArraysEqual(values, normalizedValues)) {
        setValue(normalizedValues, initialSelectedIds)
      }
    },
    [
      fieldType,
      setValue,
      values,
      normalizedValues,
      initialSelectedIds,
      isIntermediateMode,
    ]
  )

  const previousVisible = usePrevious(visible)
  useEffect(
    () => {
      if (previousVisible === undefined) return
      if (visible !== previousVisible && menuVisible !== visible) {
        setMenuVisible(visible)
      }
    },
    [menuVisible, previousVisible, visible]
  )

  useEffect(
    () => {
      if (variant === TAG_DROPDOWN_VARIANTS.DEFAULT) return
      setSortedTags(
        [...filteredTags].sort((a, b) => {
          const isASelected = selectedIds.includes(a.id)
          const isBSelected = selectedIds.includes(b.id)

          // Move selected tags to the top
          if (isASelected && !isBSelected) return -1
          if (!isASelected && isBSelected) return 1

          // If both are selected, sort alphabetically by name
          if (isASelected && isBSelected) {
            return a.name.localeCompare(b.name)
          }

          // Preserve the original order for non-selected items
          return 0
        })
      )
    },
    [filteredTags, selectedIds, variant]
  )

  return (
    <Dropdown
      overlay={menu}
      header={header}
      footer={footer}
      className={className}
      css={[
        text.styles.textSm,
        styles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
      isNavByArrowsDisabled
      offset={offset}
    >
      <div>
        {children && children}
        {!children && (
          <Dropdown.Button css={styles.dropdownBtn} size="small">
            {selectedNames || `Select tags`}
          </Dropdown.Button>
        )}
      </div>
    </Dropdown>
  )
}

export default TagsDropdown
