import React, { useMemo } from 'react'
import Link from 'redux-first-router-link'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import moment from 'moment'
import { doOpenSearchPayload } from 'actions/pages/doOpenSearchPayload'
import { propFunc } from 'util/functions'
import { pluralize } from 'util/strings'
import CellWithAgentAvatar from '../CellWithAgentAvatar'

const { CellWithButton, RowSelection, RowControls } = Table

const useColumns = ({ compact, currentUser }) => {
  const columns = useMemo(
    () => {
      const currentUserIsManager = ['admin', 'owner'].includes(currentUser.role)

      const formatName = ({ row: { original }, onEdit, selectedFlatRows }) => {
        const { name, color } = original
        return (
          <CellWithButton
            onClick={propFunc(onEdit, original.id)}
            disabled={selectedFlatRows.length > 0}
          >
            <Dot color={color} className="grui mr-5 mb-1" />
            {name}
          </CellWithButton>
        )
      }

      const formatCreator = ({ value: creator }) => {
        return <CellWithAgentAvatar agentId={creator?.id} />
      }

      const formatDate = ({ value }) => {
        if (!value) {
          return (
            <div css={[text.styles.textMediumDark, text.styles.italic]}>
              Never used
            </div>
          )
        }
        return (
          <time dateTime={moment(value).format('MM-DD-YYYY')}>
            {moment(value).format('DD MMM YYYY')}
          </time>
        )
      }

      const createRowControls = ({
        value,
        deleteData,
        onEdit,
        selectedFlatRows,
      }) => (
        <RowControls
          value={value}
          deleteData={currentUserIsManager && deleteData}
          onEdit={onEdit}
          disabled={selectedFlatRows.length > 0}
        />
      )

      const createCount = ({
        value,
        row: {
          original: { name, conversationCountDeleted },
        },
      }) => (
        <Tooltip
          title={`${value} ${pluralize(
            value,
            'conversation'
          )} including deleted`}
        >
          <span>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              css={text.styles.textPrimary}
              to={doOpenSearchPayload(null, `tag:${name}`, false, null)}
            >
              {(
                Number(value) - Number(conversationCountDeleted)
              ).toLocaleString()}
            </Link>
          </span>
        </Tooltip>
      )

      const createNameWithCheckbox = props => {
        return (
          <div className="grui flex items-center">
            {currentUserIsManager && (
              <RowSelection {...props} className="grui mr-10" />
            )}
            {formatName(props)}
          </div>
        )
      }

      return [
        {
          Header: 'TAG NAME',
          accessor: 'name',
          id: 'name',
          Cell: createNameWithCheckbox,
          width: 280,
          manualSortBy: true,
        },
        {
          Header: 'COUNT',
          accessor: 'conversationCount',
          Cell: createCount,
          width: compact ? 100 : '17%',
        },
        {
          Header: 'CREATOR',
          accessor: 'creator',
          Cell: formatCreator,
          id: 'creator',
          width: 200,
          manualSortBy: true,
        },
        {
          Header: 'CREATED',
          accessor: 'createdAt',
          Cell: formatDate,
          width: compact ? 100 : '14%',
          truncate: true,
          manualSortBy: true,
        },
        {
          Header: 'LAST USED',
          accessor: 'lastUsedAt',
          Cell: formatDate,
          width: compact ? 100 : '14%',
          truncate: true,
          manualSortBy: true,
        },
        {
          Header: '',
          accessor: 'id',
          Cell: createRowControls,
          disableSortBy: true,
        },
      ].filter(Boolean)
    },
    [compact, currentUser?.role]
  )

  return columns
}

export default useColumns
