import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import {
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'

import UserRestrictionTypeDropdown, {
  USERS_RANGES,
} from 'subapps/settings/components/UserRestrictionTypeDropdown'
import AgentsSelectionTable from 'subapps/settings/components/AgentsSelectionTable'
import TeamsSelectionTable from 'subapps/settings/components/TeamsSelectionTable'

import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { emptyArr } from 'util/arrays'
import { getRawId } from 'util/globalId'
import { getRestrictionType } from 'ducks/channels/utils'
import {
  RESTRICTION_TYPE_EVERYONE,
  RESTRICTION_TYPE_USERS,
} from 'ducks/teams/constants'

const ChannelMembers = ({
  onChange,
  defaultRestrictionType = RESTRICTION_TYPE_EVERYONE,
  defaultUserIds = [],
  defaultGroupIds = [],
  showError = false,
  channelType = app.t('mailbox'),
  channel,
  descriptionHide,
  separateTable,
  tableWrapperStyles,
}) => {
  const currentUserId = useSelector(selectCurrentUserId)

  const [draftRestrictionType, setDraftRestrictionType] = useState(
    getRestrictionType(
      {
        restriction_type: defaultRestrictionType,
        user_ids: defaultUserIds,
        group_ids: defaultGroupIds,
      },
      currentUserId
    )
  )

  const { group_ids: teamIds = emptyArr, user_ids: agentIds = emptyArr } =
    channel || {}

  const agentDefaultSelectedRowIds = useMemo(
    () => {
      const permission = agentIds.map(id => getRawId(id))
      return permission.reduce((selection, memberId) => {
        // eslint-disable-next-line no-param-reassign
        selection[memberId] = true
        return selection
      }, {})
    },
    [agentIds]
  )

  const teamDefaultSelectedRowIds = useMemo(
    () => {
      const permission = teamIds.map(id => getRawId(id))
      return permission.reduce((selection, memberId) => {
        // eslint-disable-next-line no-param-reassign
        selection[memberId] = true
        return selection
      }, {})
    },
    [teamIds]
  )

  const handleOnKeyChange = useCallback(
    type => {
      setDraftRestrictionType(type)
      const updates = {
        restriction_type: type === 'me' ? RESTRICTION_TYPE_USERS : type,
      }
      if (type === USERS_RANGES.EVERYONE) {
        updates.user_ids = []
        updates.group_ids = []
      } else if (type === USERS_RANGES.AGENTS) {
        updates.user_ids = channel.user_ids || channel.userIds
        updates.group_ids = []
      } else if (type === USERS_RANGES.ME) {
        updates.user_ids = [currentUserId]
        updates.group_ids = []
      } else if (type === USERS_RANGES.TEAMS) {
        updates.user_ids = []
        updates.group_ids = channel.group_ids || channel.groupIds
      }
      onChange(updates)
    },
    [channel, currentUserId, onChange]
  )

  const handleAgentsOnSelectedRowIdsChange = useCallback(
    uIds => {
      const ids = Object.keys(uIds).filter(id => uIds[id])
      onChange({ user_ids: ids })
    },
    [onChange]
  )

  const handleTeamsOnSelectedRowIdsChange = useCallback(
    tId => {
      const ids = Object.keys(tId).filter(id => tId[id])
      onChange({ group_ids: ids })
    },
    [onChange]
  )

  return (
    <>
      {!descriptionHide && (
        <>
          <p
            css={[
              paragraph.styles.preflight,
              text.styles.textNormal,
              text.styles.fontSemibold,
              text.styles.textSuperDark,
            ]}
            className="grui mt-14 mb-0"
          >
            Select which {app.t('agents')} have access.
          </p>
          <p
            css={[
              paragraph.styles.preflight,
              text.styles.textSm,
              text.styles.textDark,
            ]}
            className="grui mt-3 mb-11"
          >
            Grant access to specific {app.t('agents')} which should have access
            to this {channelType}.
          </p>
        </>
      )}
      <MessageCard type="negative" className="grui mb-12" visible={!!showError}>
        An error occurred adding the {app.t('agents')} to your {channelType}.
        Please try again.
      </MessageCard>
      <div>
        <UserRestrictionTypeDropdown
          selectedId={draftRestrictionType}
          onKeyChange={handleOnKeyChange}
        />
        <div className="grui mt-11">
          {draftRestrictionType === USERS_RANGES.AGENTS && (
            <AgentsSelectionTable
              defaultSelectedRowIds={agentDefaultSelectedRowIds}
              onSelectedRowIdsChange={handleAgentsOnSelectedRowIdsChange}
              separate={separateTable}
              tableWrapperStyles={tableWrapperStyles}
            />
          )}
          {draftRestrictionType === USERS_RANGES.TEAMS && (
            <TeamsSelectionTable
              defaultSelectedRowIds={teamDefaultSelectedRowIds}
              onSelectedRowIdsChange={handleTeamsOnSelectedRowIdsChange}
              separate={separateTable}
              tableWrapperStyles={tableWrapperStyles}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ChannelMembers
